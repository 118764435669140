@import "./animations";

.portfolio-container {
  padding-top: 14rem;
  margin: auto;
  margin-bottom: 100px;
  @media screen and(max-width: 991px) {
    padding-top: 11rem;
  }

  .gallery-title {
    font-family: SangBleuSunriseRegular;
    margin-top: 2rem;
    font-size: 2.8rem;
    color: #211f44;
  }
}
.row-container {
  margin-top: 5rem;
  .single-image {
    margin-bottom: 4.5rem;
    .img-slider {
      width: 100%;
    }
    .image-name {
      padding-top: 1.5rem;
      font-family: SangBleuSunriseRegular !important;
      font-size: 2.8rem;
      font-weight: 400;
      letter-spacing: 1px;
      color: #211f44 !important;
      margin-left: 2rem;
      @media screen and(max-width: 650px) {
        margin-left: 1rem;
      }
    }
  }
}
.portfolio-image-name-enlarge-view {
  color: #ffffff;
  padding-top: 2rem;
  font-size: 3rem;
}
.portfolio-content {
  .modal-body {
    max-width: 135rem;
    margin: 0 auto;
    @media screen and(max-width: 1790px) {
      max-width: 80%;
    }
  }
}
.portfolio-fullscreen {
  &.slick-slider {
    .slick-list {
      .slick-slide {
        > div {
          > .container {
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .slick-arrow {
      z-index: 1;
      background: rgba(#fff, 0.5);
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      @media (max-width: 1199.98px) {
        width: 6rem;
        height: 6rem;
      }
      &:before {
        width: 8rem;
        height: 8rem;
        display: block;
        content: "" !important;
        @media (max-width: 1199.98px) {
          width: 6rem;
          height: 6rem;
        }
      }
      &.slick-next {
        transform: translate(100%, -50%);
        &:before {
          background: url("../../../assets/images/portfolio/right.png")
            no-repeat center;
        }
      }
      &.slick-prev {
        transform: translate(-100%, -50%);
        &:before {
          background: url("../../../assets/images/portfolio/left.png") no-repeat
            center;
        }
      }
    }
  }
  .img-slider {
    width: 100%;
    height: 70rem;
    margin: 0 auto;
    @media screen and(max-width: 1500px) {
      object-fit: contain;
      height: 50rem;
    }
    @media screen and(max-width: 1000px) {
      height: 35rem;
    }
    @media screen and(max-width: 768px) {
      height: 30rem;
    }
  }
}

.modal-button {
  width: 33px;
  height: 33px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  z-index: 5;
  position: absolute;
  right: 5%;
  bottom: 6.5rem;
  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.download {
  width: 45px;
  height: 45px;
  float: right;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  margin-right: 20px;
}

.portfolio-content {
  background: transparent;
  box-shadow: none !important;
}

.portfolio-wrap .modal-dialog {
  max-width: 75% !important;
}

.portfolio-backdrop {
  opacity: 0.85 !important;
}

.modal-header .close {
  width: 26px !important;
  height: 26px !important;
  background: transparent !important;
  // TODO: this should also be fixed, for start I moved image to assets folder
  background-image: url("../../../assets/images/portfolio/close.png") !important;
  background-position: center !important;
  background-size: 70% !important;
  background-repeat: no-repeat !important;
  margin-right: -100px;
}

.modal-header .close span {
  display: none;
}

.download-text {
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  float: right;
  margin-right: 10px;
  margin-top: 25px;
  margin-bottom: 0px;
}

.modal-download {
  float: right;
  margin-top: 15px;
  margin-bottom: -15px;
  width: 39px;
  height: 40px;
}

.portfolio-gallery-modal .modal-dialog-centered .modal-header {
  background: transparent !important;
}

.portfolio-gallery-modal {
  .modal-header {
    background: transparent !important;
  }
}

.modal-title {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

@media (max-width: 576px) {
  .portfolio-container {
    padding-top: 7rem;
    margin-bottom: 7rem;
    h3 {
      &.gallery-title {
        font-size: 2rem;
      }
    }
    .row-container {
      margin-top: 2.5rem;
      .single-image {
        margin-bottom: 2rem;
        .image-name {
          font-size: 1.6rem;
        }
      }
    }
  }

  .modal-button {
    display: none;
  }
}
