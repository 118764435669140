.big-part {
  div {
    background: #171626;
    padding: 0px;
    padding-bottom: 9rem;
    flex-direction: column;

    .h5 {
      max-width: 37rem;
      span,
      p {
        font-family: SangBleuSunriseRegular !important;
        text-align: center;
        color: #fff;
        font-size: 2.8rem;
        font-weight: 300;
        line-height: normal;
      }
    }

    a {
      color: #fff;
      margin-top: 2rem;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 1px;
      color: #ffffff;
      &:hover {
        text-decoration: underline;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.smaller-parts {
  flex-direction: column;

  > div {
    flex: 1;
  }

  .blue-box {
    flex-direction: column;
    background: #2f3181;
    text-align: center;
    p {
      font-family: SangBleuSunriseRegular !important;
      color: #fff;
      font-weight: 300;
      font-size: 2.8rem;
    }
    a {
      color: #fff;
      font-weight: 600;
      margin-top: 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
    h5 {
      color: #fff;
      font-weight: 300;
    }
    + {
      div {
        padding: 0px;
        overflow: hidden;
        img {
          @media screen and (max-width: 2561px) {
            height: 52rem;
          }
          @media screen and (max-width: 1921px) {
            height: 45rem;
          }
          @media screen and (max-width: 1500px) {
            height: 36.5rem;
          }
          @media screen and (max-width: 992px) {
            height: 41rem;
          }
          @media screen and (max-width: 600px) {
            height: 31rem;
          }

          max-width: 100% !important;
          max-height: 100% !important;
          flex-shrink: 0;
          object-fit: cover;
        }
      }
    }
  }
}

.catalog-part {
  align-items: center;
  @media screen and(max-width: 1200px) {
    margin-top: 5rem;
  }
  background-color: #fbfcf5;

  div {
    flex-direction: column;
  }
  h6 {
    p {
      font-family: SangBleuSunriseRegular !important;
      font-size: 2.4rem;
      font-weight: 200;
      color: #000000;
    }
  }
  a {
    i {
      color: #151522;
      margin-top: 1rem;
    }
    color: #fbfcf5;
    font-family: Montserrat;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 900;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    object-fit: scale-down;
  }
  .catalog-link {
    a {
      display: flex;
      &:after {
        content: "";
        background: url("../../../assets/icon/downloadCatalog.svg") center
          no-repeat;
        position: absolute;
        margin-top: 1rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .smaller-parts {
    .blue-box {
      padding: 4rem 3.5rem;
    }
    a {
      padding-left: 0;
    }
  }
}

@media (max-width: 991px) {
  .mobile-change-side {
    flex-direction: row-reverse;
  }

  .find-rep-image {
    p {
      .imageloader-loaded {
        img {
          // min-height: auto !important;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .smaller-parts {
    .catalog-part {
      padding: 2rem 1.5rem 3rem;
      margin-top: 0;
      text-align: center;
      .catalog-link {
        a {
          justify-content: center;
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .big-part {
    div {
      .h5 {
        line-height: 2.5rem;
        span {
          font-size: 1.8rem;
        }
      }
      a {
        text-transform: uppercase;
      }
    }
  }
  .smaller-parts {
    .blue-box {
      text-align: left;
      .h5 {
        p {
          font-size: 1.8rem;
        }
      }
      a {
        text-transform: uppercase;
      }
    }
    .catalog-part {
      .h6 {
        p {
          font-size: 1.8rem;
        }
      }
      a {
        font-size: 1.4rem;
      }
    }
  }
}
