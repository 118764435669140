.about-text-part-prima {
  padding: 8rem 0;

  .about-text-prima-columns {
    margin: 0 auto;
    max-width: calc(100% - 74rem);
    min-width: calc(100% - 74rem);
    justify-content: space-between;
  }

  .h1 {
    color: #15152a;
    margin-bottom: 3rem;
    max-width: calc(100% - 120rem);
    min-width: 50%;
    margin-left: 15%;
  }
  p {
    color: #49494f;
    margin-bottom: 2rem;
  }
  .h6 {
    p {
      letter-spacing: 1.5px;
      color: #1e1b3b;
      font-size: 2.4rem;
    }
  }
}
/*Media Queries*/
@media (min-width: 1981px) {
  .about-text-part-prima,
  .text-part {
    .h1 {
      margin-left: 10% !important;
    }
  }
}

@media (max-width: 1400px) and (min-width: 1025px) {
  .about-text-part-prima {
    padding: 11rem 0;
    .about-text-prima-columns {
      min-width: 70%;
    }
  }
}

@media (max-width: 1024px) {
  .about-text-part-prima {
    padding: 9rem 0;
    .h1 {
      margin-left: 5%;
      font-size: 3.4rem;
    }
    .about-text-prima-columns {
      min-width: 90%;
      padding: 0;
    }
  }
  .tabs-section {
    .tabs-container {
      .main-block-tabs {
        .product-image-left {
          padding-left: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .about-text-part-prima {
    padding: 5rem 0;
    margin: 0 auto;
    .about-text-prima-columns {
      min-width: 85%;
    }
    .h1 {
      min-width: 85%;
      margin: 0 auto 3rem;
      font-size: 3.2rem;
      text-align: left;
    }
    p {
      font-size: 1.6rem;
      text-align: left;
    }
  }
}
@media (max-width: 575.98px) {
  .about-text-part-prima {
    padding-top: 3rem;
    h2 {
      &.h1 {
        margin-bottom: 2rem;
        span {
          font-size: 2rem;
        }
      }
    }
    p {
      font-size: 1.4rem;
    }
    .h6 {
      p {
        font-size: 2rem;
      }
    }
  }
  .tabs-section {
    .tabs-header {
      h3 {
        &.h5 {
          font-size: 2rem;
          padding-top: 3rem;
        }
      }
    }
    .tabs-container {
      .text-part {
        h2 {
          &.h3 {
            span {
              font-size: 2rem;
            }
          }
        }
        p {
          margin-top: 1rem;
          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
