.parameters-section {
  margin-top: 7.6rem !important;
  @media screen and (max-width: 769px) {
    margin-top: 0rem !important;
    .md-accordion {
      margin-top: 0 !important;
    }
  }
  .parameters-section-wrapper {
    .left-section {
      p {
        text-transform: uppercase;
        line-height: 2.5;
        letter-spacing: 0.1px;
        @media screen and (max-width: 1281px) {
          font-size: 1.4rem !important;
        }
      }
    }

    .right-section {
      ul,
      li {
        font-family: "Montserrat";
        font-size: 1.8rem;
        margin-bottom: 2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: 0.06px;
        padding-left: 2rem;
      }
      ul {
        li {
          &::marker {
            margin-right: 0;
          }
          @media screen and (max-width: 1281px) {
            font-size: 1.4rem;
          }
        }
      }
    }
    .not-active {
      padding-left: 2rem;
      cursor: pointer;
    }
    .active {
      padding-left: 2rem;
      border-left: 3px solid #211f44;
      font-family: 600;
      color: #211f44;
    }
  }
  h1 {
    padding-bottom: 2.3rem;
    border-bottom: 1px solid #e1e1e1 !important;
  }

  .md-accordion {
    margin-top: 5.6rem;
  }

  .card-header {
    border-bottom: solid 1px #979797 !important;
    padding: 1rem 0 !important;
    margin-bottom: 0 !important;
  }

  .card-header h5 {
    font-size: 28px;
    line-height: 1.04;
    letter-spacing: 1.5px;
    color: rgba($color: #211f44, $alpha: 0.9) !important;
  }
  @media screen and (max-width: 768px) {
    text-align: left;

    .parameters-section-wrapper {
      margin-bottom: 5.6rem;
      .left-section {
        margin-bottom: 4rem;
      }
      .right-section {
        padding-left: 4rem;
      }
    }
  }
}
.parameters-section-arkansas {

  @media screen and (max-width: 769px) {
    margin-top: 0rem !important;
    .md-accordion {
      margin-top: 0 !important;
    }
  }
  .parameters-section-wrapper {
    p {
      font-size: 1.8rem; color: rgb(0, 0, 0); margin-bottom: 2rem;
      font-family: SangBleuSunrise, serif;
      @media screen and (max-width: 1281px) {
        font-size: 1.4rem !important;
      }
    }


    .right-section {
      ul,
      li {
        font-family: "Montserrat";
        font-size: 1.8rem;
        margin-bottom: 2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: 0.06px;
        padding-left: 2rem;
      }
      ul {
        li {
          &::marker {
            margin-right: 0;
          }
          @media screen and (max-width: 1281px) {
            font-size: 1.4rem;
          }
        }
      }
    }
    .not-active {
      padding-left: 2rem;
      cursor: pointer;
    }
    .active {
      padding-left: 2rem;
      border-left: 3px solid #211f44;
      font-family: 600;
      color: #211f44;
    }
  }
  h3 {
    font-size:3rem;
    padding-bottom: 2.3rem;
    border-bottom: 1px solid #e1e1e1 !important;
  }

  .md-accordion {
    margin-top: 5.6rem;
  }

  .card-header {
    border-bottom: solid 1px #979797 !important;
    padding: 1rem 0 !important;
    margin-bottom: 0 !important;
  }

  .card-header h5 {
    font-size: 28px;
    line-height: 1.04;
    letter-spacing: 1.5px;
    color: rgba($color: #211f44, $alpha: 0.9) !important;
  }
  @media screen and (max-width: 768px) {
    text-align: left;

    .parameters-section-wrapper {
      margin-bottom: 5.6rem;
      .left-section {
        margin-bottom: 4rem;
      }
      .right-section {
        padding-left: 4rem;
      }
    }
  }
}
