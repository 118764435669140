@import "./downloadSection/downloadSection";
@import "./parameters-section/parametersSection";
@import "./options-section/optionsSection";

$darkGrayBorder: 2px solid #979797;
$fontFamilyTypography: Montserrat;

#product-page-container {
  padding-top: 12.5rem;
  .slick-slider.slider.slick-vertical.slick-initialized {
    position: relative;
    top: -61px;
  }
  .product-name-and-3D-icon {
    align-items: center;
    @media screen and(max-width: 600px) {
      justify-content: center;
    }
    display: flex;
    svg {
      margin-left: 2rem;
    }
  }

  .cursor-pointer:hover {
    cursor: pointer;
  }
  .catalog {
    font-family: "Montserrat";
    font-size: 1.4rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.88px;
    color: #1f1e3f;
    &.text-bold {
      font-weight: 500;
    }
  }
}

.breadcrumbs-row {
  max-width: calc(100% - 152px);
  @media screen and(max-width: 1024px) {
    max-width: calc(100% - 100px);
  }
  margin: 0 auto;
  .breadcrumbs-title,
  .breadcrumbs-title-responsive {
    // margin-top: 3.5rem;
    .h2 {
      font-size: 3.6rem;
    }
    font-size: 1rem;
    // > div {
    //   display: flex;
    //   align-items: center;
    //   a,
    //   span {
    //     font-size: inherit;
    //     display: inline-block;
    //     padding: 0 0.5rem;
    //   }
    //   a {
    //     padding: 0;
    //     span {
    //       border-right: 1px solid #212529;
    //       &:after,
    //       &:before {
    //         content: "";
    //         background-color: #fff;
    //         position: absolute;
    //         right: 0;
    //         width: 1rem;
    //         height: 0.6rem;
    //       }
    //       &:after {
    //         top: 0;
    //       }
    //       &:before {
    //         bottom: 0;
    //       }
    //     }
    //   }
    // }
    .current-breadcrumb-item {
      padding-left: 0.5rem;
    }
  }
}

.custom-shadow {
  @media screen and(max-width: 991px) {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px;
  }
}

.size-dimension-images {
  img {
    width: 100%;
    background: transparent;
    padding-left: 20%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-right: 20%;
  }
}

.main-row-pdp {
  max-width: calc(100% - 152px);
  margin: 0 auto 55px auto !important;
  display: flex;
  justify-content: space-between;

  @media screen and(max-width: 1024px) {
    max-width: calc(100% - 100px);
  }
  .top-left-section {
    max-width: 70rem;
    height: 100%;

    .pdp-select-wrapper {
      .catalog-code {
        font-size: 1.4rem;
        font-family: Montserrat;
        font-weight: 300;
        color: #000000;
      }
    }

    .sku-price-wrapper {
      display: flex;
      align-items: center;
      margin: 0 auto 1rem;
    }
    @import "./quote-request-button/quote-request-btn";
  }

  .fullscreen-canvas {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    z-index: 10000 !important;
    background-color: white;
  }
  .enlarge-pdp {
    cursor: pointer;
    top: 5%;
    right: 5%;
    position: absolute;
    z-index: 2;
  }
  .close-fullscreen-img {
    cursor: pointer;
    position: fixed;
    z-index: 10001;
    top: 5%;
    right: 5%;
    @media screen and(max-width: 993px) {
      top: 12%;
      right: 10%;
    }
  }
  .top-middle-section {
    position: relative;
    @media screen and(max-width: 600px) {
      margin-bottom: 2rem;
    }
    height: 65rem;
    max-height: 65rem;
    background-color: white;
    width: 100%;
    .player-wrapper {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      #player {
        height: 100%;
        width: 100%;
        canvas {
          @media screen and(max-width: 993px) {
            padding-left: 0;
            padding-right: 0;
          }
          height: 100% !important;
          width: 100% !important;
        }
      }
      .enlarge-pdp-model {
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 5%;
      }
      .three-d-icon-box {
        position: absolute;
        left: 5%;
        bottom: 5%;
        svg {
          height: auto;
          width: 4rem;
          @media screen and(max-width: 993px) {
            height: auto;
            width: 3.3rem;
          }
        }
        @media screen and(max-width: 993px) {
          bottom: 5%;
          left: 10%;
        }
        cursor: pointer;
      }
      .close-fullscreen {
        cursor: pointer;
        position: absolute;
        right: 5%;
        top: 5%;
        @media screen and(max-width: 993px) {
          top: 12%;
        }
      }
    }

    .product-image {
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: auto;
      width: 70%;
      height: 100%;
      object-fit: contain;
    }
  }

  .catalog-items {
    cursor: pointer;
    // Safari and Firefox
    --scrollbarBG: #ebebeb;
    --thumbBG: #b3b9b9;
    overflow-y: hidden !important;
    overflow-y: scroll;
    padding: 1rem 0;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
  }
  /*Chrome*/
  .catalog-items::-webkit-scrollbar {
    width: 1rem;
    height: 0.8rem;
  }
  .catalog-items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gray;
  }

  .catalog-items::-webkit-scrollbar-thumb {
    background: #1f1e3f;
  }

  .catalog-items::-webkit-scrollbar-thumb:hover {
    background: #201e40;
  }

  .text-left {
    text-transform: uppercase;
  }

  .select-wrapper.select-small {
    padding: 0.3rem !important;
    max-width: 49%;
    margin-top: 0.3rem !important;
    font-family: $fontFamilyTypography;
    letter-spacing: 0.75px !important;
    line-height: 1.5rem !important;
    display: inline-block !important;
    border-color: 1px solid #979797 !important;
  }

  .catalog-item,
  .catalog-item-active {
    max-width: 7rem;
    max-height: 7rem;
  }

  .catalog-item-active {
    border-radius: 3px;
    border: solid 1px #201e40;
  }

  .select-wrapper {
    width: 100% !important;
  }

  .select-wrapper.select-small {
    padding: 0.3rem !important;
    max-width: 49%;
    margin-top: 0.3rem !important;
    font-family: $fontFamilyTypography;
    letter-spacing: 0.75px !important;
    line-height: 1.5rem !important;
    display: inline-block !important;
    border-color: 1px solid #979797 !important;

    .hart-image {
      display: inline-block;
      max-width: 15%;
    }

    label {
      background: white !important;
      z-index: 2 !important;
    }
  }

  .paragraph-border-bottom {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #979797;
  }

  .large-button {
    max-width: 100% !important;
  }

  .hart-image {
    display: inline-block;
    max-width: 15%;
  }

  .questions-box:hover,
  .options-box:hover {
    cursor: pointer;
  }
}
@import "./productDescription/productDescription";

.vertical-list li {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 2.22;
  letter-spacing: 0.11px;
  color: #000000;
}

.size-dimension-image {
  display: inline-block;
  margin: 1rem 0.1rem 1rem 0rem;
  max-width: 195px;
}

.main-title {
  margin-top: 15rem;
}

.right-corner-image {
  display: block !important;
}

.vplayer-left {
  .lifestyle-img-wrapper {
    width: 100%;
  }
}

.vplayerColLeft {
  padding: 0 !important;
}

.vplayerColLeft,
.vplayerColRight {
  .lifestyle-img-wrapper {
    p {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.pdp-lifestyle-images-wrapper > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.pdp-lifestyle-image-wrapper > div {
  display: grid;
  max-width: 50%;
  margin: auto;
  margin-top: 6rem;
}

.pdp-lifestyle-image img {
  max-width: 100%;
}

.vplayerColRight {
  padding: 0 0 0 0.1rem !important;
}

.product-card {
  display: inline-block !important;
}

.top-right-image {
  padding-right: 0 !important;
}

.scrolling-wrapper-flexbox {
  margin-top: 10rem !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  scrollbar-color: black;
  padding-bottom: 5rem;
}

.scrolling-wrapper-flexbox::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scrolling-wrapper-flexbox::-webkit-scrollbar-track-piece {
  background-color: #e1e1e1;
}

.scrolling-wrapper-flexbox::-webkit-scrollbar-thumb {
  background-color: #555;
}

.related-products {
  width: 350px;
  height: 450px;
  flex: 0 0 auto;
}

.vplayer-right {
  .lifestyle-img-wrapper {
    width: 100%;
  }
}
// Media Mobile

@media (max-width: 992px) {
  #product-page-container {
    padding-top: 10rem;
    .breadcrumbs-row {
      max-width: calc(100% - 50px);
      .breadcrumbs-title {
        .breadcrumbs-paths {
          a,
          span {
            font-weight: 300;
            font-size: 0.8rem;
            line-height: 1.4;
            letter-spacing: 0.5px;
            font-family: "Montserrat";
            border-right: 0.5px solid #a5a5a5;
          }
          a:first-child {
            span {
              padding: 0 1rem 0 0;
            }
          }
        }
      }
    }
    .main-row-pdp {
      max-width: calc(100% - 50px);
      margin: 0 auto 35px !important;
      .top-middle-section {
        order: 1;
        height: 36rem;
        position: -webkit-sticky;
        position: sticky;
        z-index: 9;
        top: 7rem;
        &.no-sticky {
          position: relative !important;
          top: 0;
        }
        .player-wrapper {
          #player {
            canvas {
              object-fit: cover;
            }
          }
        }

        .degree-icon-box {
          background-color: white;
          .degree-icon {
            margin: 0 0 0 auto;
            width: 4.3rem;
          }
        }

        .product-image {
          height: 100%;
          width: 70%;
          padding-bottom: 3rem;
        }
      }

      .top-left-section {
        max-width: 100%;
        order: 2;
        .pdp-select-wrapper {
          max-width: 100% !important;
          text-align: left;
          p {
            line-height: 1.8rem;
            margin-bottom: 0;
            text-align: left;
          }

          .catalog-items {
            border-top: 1px solid#979797;
            border-bottom: 1px solid#979797;
          }
          .catalog,
          .catalog-code {
            font-size: 1.2rem;
          }

          .questions-box:first-child {
            margin-top: 3rem;
          }
          .questions-box {
            .input-box-pdp {
              height: 100%;
            }

            .input-options-pdp {
              .two-rows {
                width: 100%;
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
            }
          }
        }

        .sku-price-wrapper {
          margin-top: 2rem;
          padding-bottom: 2rem;
          border-bottom: 1px solid #e1e1e1;

          .catalog {
            display: flex;
          }
          .price {
            margin-right: 1.5rem;
          }
        }

        .add-to-quote-request {
          margin: 3rem auto;
          .large-button {
            padding: 1.3rem 0 !important;
          }
          .heart-icon {
            justify-content: center;
            img {
              width: 2.5rem;
            }
          }
        }
        .download-section-wrapper {
          .span-logo {
            img {
              width: 2.9rem;
              margin-right: 0.9rem;
            }
            span {
              font-size: 0.8rem;
            }
          }
        }
      }
      .top-right-section {
        order: 3;
        .pdp-small-slider-container {
          // display: block;
          .slick-slider {
            max-height: 100%;

            .slick-list {
              // width: 60%;
              // margin: 0 auto;

              .slick-slide {
                // width: 146px !important;
                // height: auto;
              }
            }

            .slick-arrow.slick-prev {
              z-index: 1;
              top: 40%;
              left: 0;
              transform: rotate(0);
              &::before {
                color: black;
              }
            }
            .slick-arrow.slick-next {
              z-index: 1;
              top: 40%;
              left: 94%;
              transform: rotate(0deg);
              &::before {
                color: black;
              }
            }
          }
        }
      }
    }

    .pdp-lifestyle-image-wrapper > div {
      max-width: 100%;
    }

    .description-wrapper {
      margin: 0 auto 0;
      .data-row {
        max-width: 100%;
        .description-and-size-wrapper {
          padding-right: 0;
          padding-bottom: 0;
          .tab-content {
            .tab-pane {
              img {
                height: 30rem !important;
              }
            }
          }
          @import "./productDescription-mobile/productDescription-mobile";
        }
        @import "./parameters-section-mobile/parametersSectionMobile";
      }
    }
  }
}
@media (max-width: 575.98px) {
  #product-page-container {
    .h2 {
      font-size: 2rem;
      text-align: left;
    }
  }
}

@media (max-width: 480px) {
  #product-page-container {
    .main-row-pdp {
      .top-right-section {
        .pdp-small-slider-container {
          // display: block;
          .slick-slider {
            // max-height: 9rem;
            .slick-list {
              // width: 90%;
              // margin: 0 auto;
              .slick-slide {
                width: 100px !important;
                height: 100px;
              }
            }

            .slick-arrow.slick-prev {
              top: 40%;
              left: 0;
              transform: rotate(0);
              &::before {
                color: black;
              }
            }
            .slick-arrow.slick-next {
              top: 40%;
              left: 94%;
              transform: rotate(0deg);
              &::before {
                color: black;
              }
            }
          }
        }
      }
    }
    .breadcrumbs-row {
      .breadcrumbs-title {
        .breadcrumbs-paths {
          a:first-child {
            span {
              padding-right: 0.5rem;
            }
          }
        }
      }
    }
    .breadcrumbs-title > div a span:after,
    .breadcrumbs-title > div a span:before {
      content: none;
    }
    .breadcrumbs-row .breadcrumbs-title .breadcrumbs-paths a {
      border-right: 1px solid;
    }
    .breadcrumbs-title > div a span {
      border-right: none !important;
    }
    .current-breadcrumb-item {
      border-right: none !important;
    }
  }
}

.customer-service-wrapper {
  span,
  a {
    font-size: 1.2rem;
  }

  display: flex;
  flex-direction: column;
}

.description-wrapper-arkansas {
  padding-top: 10rem;
  p {
    font-size: 1.4rem;
    color: #1f1e3f;
    margin-bottom: 0.4rem;
  }
  @media screen and(max-width: 993px) {
    padding: 5rem 20px;

    h3 {
      font-size: 2rem;
    }
    p {
      font-size: 1.4rem;
    }
  }
}

table.availability-table-ark {
  background: #fbfcf5;
  text-align: center;
  border: 1px solid #e1e1e1;

  th {
    font-size: 1rem;
    vertical-align: middle;
  }
}

#product-page-container .availability-table-ark-sku-price-wrapper {
  span {
    font-size: 1.1rem;
    color: black;
    font-weight: 400;
  }
}
.show.tooltip {
  background: #fff;
  color: #0d0d0d;
  font-size: 12px;
  padding: 10px 10px !important;
  z-index: 8;
}
