.home-banner-slider {
  padding-top: 2.5rem;

  .mobile-reverse {
    display: flex !important;
    min-height: 75.6rem;
  }

  .slick-slide {
    > div {
      display: flex;
      justify-content: center;
    }
  }

  .slick-dots {
    bottom: 3rem;
    width: auto;
    left: 29%;
    transform: translateX(-29%);
    -webkit-transform: translateX(-29%);
    -moz-transform: translateX(-29%);
    -ms-transform: translateX(-29%);
    -o-transform: translateX(-29%);

    li {
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      height: 2.8rem;
      width: 2.8rem;
      cursor: auto;
      button {
        &::before {
          color: transparent;
        }
      }
      &.slick-active {
        border: 1px solid #fff;
      }

      button {
        background: #fff;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        padding: 0;
      }
    }
  }

  .product-part {
    display: flex;
    flex-direction: column;
    height: 100%;

    .image-part {
      //ljubica was here
        padding-top: 12.6rem !important;

      @media screen and(min-width: 1981px) {
         padding-top: 12.6rem !important;
      } //ljubica was here
      @media screen and(max-width: 1400px) {
        padding-top: unset;
      }
      flex: 1 0 75%;
      // background: #fbfcf5;
      padding: 0 1.5rem;
    }

    .text-part {
      .view-product-link {
        @media screen and(max-width: 1400px) {
          font-size: 1.3rem;
        }
        margin-top: 2rem;
        font-family: Montserrat;
        font-size: 1.4rem;
        font-weight: 500;
        letter-spacing: 0.88px;
        color: #ffffff;
        &:hover {
          text-decoration: underline;
        }
      }
      .product-description {
        @media screen and(max-width: 1400px) {
          font-size: 1.4rem;
        }
        font-size: 1.8rem;
        font-family: Montserrat !important;
        line-height: normal;
        font-weight: 300;
        letter-spacing: 1.13px;
      }
      flex: 1 1 15%;
      background: $black;
      padding: 1.6rem 3rem;
      flex-direction: column;

      .h6 {
        color: #fff;
      }

      p {
        color: #fff;
        margin-top: 1rem;
      }
    }
  }

  .title-part {
    height: 100%;
    flex-direction: column;
    background: #2f3181;
    padding: 35rem 4rem 25rem;

    .h1 {
      max-width: 29rem;
    }

    a {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.88px;
      line-height: 5rem;
      color: #fff;
      border: solid 1px #ffffff;
      margin-top: 6.6rem;
      max-width: 25rem;
      font-size: 1.4rem;
    }
  }

  .banner-image {
    height: 100%;
    // max-height: 756px;

    img {
      height: 100%;
      width: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }
}

@media (min-width: 1981px) {
  .home-banner-slider {
    height: 90vh;
    .slick-slide > div {
      height: 100%;
    }
    .product-part {
      height: 90vh;
    }
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
      }
    }
  }
}
/*ljubica was here*/
@media (max-width: 1980px) and (min-width: 1401px) {
.home-banner-slider {
    height: 90vh;
    .slick-slide > div {
      height: 90vh;
    }
    .product-part {
      height: 90vh;
    }
  .title-part {
    height: 90vh;
  }
    .slick-list {
      height: 90vh;
      .slick-track {
        height: 90vh;
      }
    }
  }
}
/*ljubica was here*/

@media (max-width: 1400px) {
  .home-banner-slider {
    .slick-dots {
      left: 31%;
    }

    .title-part {
      padding: 10rem 4rem;
    }
  }
}

@media (max-width: 1280px) {
  .home-banner-slider {
    h1 {
      font-size: 2.8rem;
    }
    p {font-size: 1.2rem}
    h6 {font-size: 2.2rem}
    .title-part {
      padding: 6rem 3rem 3rem 3rem;

      a {
        width: 100% !important;
        font-size:1.2rem;
      }
    }
    .slick-dots {
      left: 36%;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .home-banner-slider {
    padding-top: 7%;

    .slick-dots {
      top: 62%;
      left: 59% !important;
    }
    .banner-image {
      height: 30rem;
    }
  }
}

@media (max-width: 767px) {
  .home-banner-slider {
    padding-top: 7rem;
    .view-more-btn {
      display: none !important;
    }
    .slider-left {
      display: none !important;
    }

    .slider-right {
      order: 1;
      padding: 0 !important;
    }

    .slider-middle {
      order: 2;
      padding: 0 !important;
    }
    .slick-list {
      height: 50.6rem !important;
    }
    .mobile-reverse {
      display: flex !important;
      min-height: 47.6rem;
    }

    .product-part {
      display: none;
    }

    .title-part {
      // padding: 5rem 3rem 10rem;
      padding: 3rem;
      position: relative;
      top: -22px;

      .h1 {
        text-align: left;
        max-width: 100% !important;
        font-size: 2.8rem;
      }

      a {
        min-width: 100% !important;
      }
    }

    .banner-image {
      height: 33.4rem !important;

      img {
        height: 100% !important;
        width: 100% !important;
        overflow: hidden;
      }
    }

    .slick-dots {
      // bottom: 2.5rem;
      margin: auto !important;
      left: 4% !important;
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
    }
  }
}

@media (max-width: 480px) {
  .home-banner-slider {
    .slick-dots {
      bottom: 1.5rem;
    }
  }
}
